h1, h2, h3, h4, h5{
  font-family: 'Roboto Condensed', sans-serif;
}

p{
  font-family: 'Roboto', sans-serif;
}

* {
  box-sizing: border-box;
}

*:before, *:after {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  position: relative;
  /*z-index: -100;*/
}

.App {
  text-align: center;
  min-height: 100vh; /* will cover the 100% of viewport */
  overflow: hidden;
  display: block;
  position: relative;
}

.burger_h4{
  color: #ccc;
  margin: 20px;
  text-decoration:none;
}

.header {
  background-color: #333333;
  color: white;
  position: fixed;
  width: 100%;
  height: 60px;
  z-index: 10;
}

.video{
  position: relative;
  padding-top: 60px;
  background-color: #333333;
}

video{
  width: 100%;
  height: auto;
}

.logo{
  float: left;
  margin-left: 10px;
  margin-top: 5px;
}

.ul_menu{
  list-style-type: none;
  margin: 0px;
  margin-left: 0px;
  padding: 0px;
  float:left;
  position: relative;
  height:100%;
}

.ul_social{
  list-style-type: none;
  margin: 0px;
  margin-left: 0px;
  padding: 0px;
  padding-right: 70px;
  float:left;
  position: relative;
  height:100%;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 15px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #ccc;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #1B76BE;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 30px;
  width: 30px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #ccc;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #1B76BE;
  color: white;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0,0,0,0.5) !important;
}

.menu{
  float:left;
  margin-left: 50px;
  height: 100%;
}

.li_menu{
  float: left;
  width: 130px;
  margin-right: 25px;
  border-bottom: 3px solid #ccc;
  transition: background 1s ease-in-out;
  height:100%;
  transition: 0.5s;
}

.li_menu:hover{
  border-bottom: 7px solid #1B76BE;
  color:#1B76BE;
}

.li_menu:hover h4{
  color:#1B76BE;
}

.a_menu{
  display: block;
  color: white;
  text-align: center;
  height: 100%;
  padding-top: 25px;
  text-decoration: none;
}

.left{
  height:100%;
  float:left;
}

.right{
  float:right;
  height:100%;
}

.a_socials_twitch{
  margin:0px;
  margin-top: 19px;
  display: block;
  color: white;
  text-align: center;
  height: 100%;
  text-decoration: none;
  transition: 0.3s;
}

.a_socials_twitter{
  margin:0px;
  margin-top: 19px;
  display: block;
  color: white;
  text-align: center;
  height: 100%;
  text-decoration: none;
  transition: 0.3s;
}

.a_socials_discord{
  margin:0px;
  margin-top: 19px;
  display: block;
  color: white;
  text-align: center;
  height: 100%;
  text-decoration: none;
  transition: 0.3s;
}

.a_socials_youtube{
  margin:0px;
  margin-top: 19px;
  display: block;
  color: white;
  text-align: center;
  height: 100%;
  text-decoration: none;
  transition: 0.3s;
}

.a_socials_facebook{
  margin:0px;
  margin-top: 19px;
  display: block;
  color: white;
  text-align: center;
  height: 100%;
  text-decoration: none;
  transition: 0.3s;
}

.a_socials_facebook:hover{
  color:#3B5998;
}
.a_socials_twitter:hover{
  color:#55ACEE;
}
.a_socials_youtube:hover{
  color:#bb0000;
}
.a_socials_twitch:hover{
  color:#6441A5;
}
.a_socials_discord:hover{
  color:#7289da;
}

.social{
  float: left;
  height:100%;
}

.socials{
  display: block;
  text-align: center;
  margin: 0px;
  text-decoration: none;
  float:left;
}

.li_socials{
  float: left;
  margin-right: 10px;
  margin-left: 10px;
  font-size: 22px;
  transition-duration: 0.4s;
}

.button {
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  border-radius: 15px;
  font-size: 16px;
  cursor: pointer;
}

.apply{
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
}

.button3 {
  color: white;
  background-color: #BE1E2D;
}

.button3:hover {
  background-color: #1B76BE;
}

.account{
  float: left;
  height: 100%;
  margin-right: 20px;
  margin-top: 10px;
}

a {
  text-decoration: none;
  color: #BE1E2D;
}
a:focus {
  outline:none;
}
button:focus{
  outline:none;
}

.account_a{
  display: block;
  color: white;
  text-align: center;
  text-decoration: none;
  margin: 14px 16px;
}

.body {
  font-size: calc(10px + 2vmin);
  font-family: 'Roboto', sans-serif;
}

.body_news {
  font-size: calc(10px + 2vmin);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: flex-start;
}

.news{
  padding: 20px;
}

.news_border{
  border: 1px solid rgba(0,0,0,.125);
  margin-left: 40px;
  margin-right: 40px;
}

.sponsors_h3{
  color: #333333;
  padding-bottom: 30px;
}

.sponsors {
  background-color: #cccccc;
  padding: 20px 10px 10px 10px;
}

.sponsor_img{
  max-width: 300px;
  max-height: 300px;
}

footer {
  background-color: #333333;
  color: white;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.bottom_footer{
  margin: 0 auto;
  display: inline-block;
}

.footer_left {
  float:left;
  padding: 10px 70px 10px 70px;
  z-index:100;
  text-align: left;
}

.footer_right{
  float:left;
  padding: 20px 70px 10px 70px;
  z-index:100;
}

.foot_h5{
  float:left;
  margin-right: 20px;
  height: 100%;
}

.foot_h5:hover{
  color: white;
}

.selector_outer_border{
  margin: 50px;
  height: 80px;
}

.selector_div{
  box-shadow: 0 5px 10px rgba(0, 0, 0, .7);
  border-left: 20px solid white;
  border-right: 20px solid white;
  height: 100%;
}

.red_border{
  border-left: 20px solid #BE1E2D;
  border-right: 20px solid #BE1E2D;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #333333;
  height: 100%;
}

.selector{
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 10px;
  margin-left: 10px;
  float: left;
  width: 200px;
}

.checkbox_div{
  float: right;
  margin-right: 50px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.flex{
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: flex-start;
}

.discord{
  background-color: #7289da;
  height: auto;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  padding: 30px;
  margin-top: 80px;
  margin-bottom: 80px;
  z-index: 10;
}

.dis_text{
  text-align: left;
}

.dis_logo{
  width: 300px;
  height: 86px;
}

.dis_h4{
  padding-bottom: 10px;
  color: white;
}

.dis_h6{
  margin: 0px;
  font-weight: normal;
  color: white;
}

.flex_children{
  flex-basis: auto;
  width: 400px;
  height: auto;
  margin: 25px;
}

@media (max-width : 1254px) {
  .disc_img{
    margin-top: 20px;
  }
}

@media (max-width : 1150px) {
  .checkbox_div{
    float: none;
  }

  .flex_children {
    width: 300px;
  }

  .red_border{
    border-left: 10px solid #BE1E2D;
    border-right: 10px solid #BE1E2D;
    padding-top: 5px;
  }

  .selector_div{
    border-left: 10px solid white;
    border-right: 10px solid white;
  }

  .selector{
    float: none;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 200px;
  }

  .checkbox_div{
    float: none;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .selector_outer_border{
    height: 250px;
  }

  label{
    padding-right: 9px;
  }
}

.h_event{
  color: white;
}

label{
  font-size: 1em;
  font-weight: bold;
  color: #ccc;
}

.privacy_policy{
  text-align: left;
  padding: 0px 20px 0px 20px;
  word-wrap: break-word;
  overflow: hidden;
}

.h3_pp, .h4_pp{
  color: black;
  margin-top: 10px;
}

.h4{
  font-size: 17px;
}

.h4_menu{
  font-size: 17px;
}

.live_on_twitch{
  z-index: 100000;
}

.flex_news{
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: flex-start;
}

.flex_children_news{
  flex-basis: auto;
  margin: 25px;
  background-color: #333333;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .7);
}

.img{
  flex-basis: auto;
  padding: 10px;
}

.text{
  flex-basis: auto;
  padding: 10px;
  text-align: justify;
  min-width: 250px;
  max-width: 400px;
}

.text_flex{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.news_date{
  float:left;
  height: 100%;
}

.news_bottom{
  width: 100%;
}

.news_headline{
  padding-bottom: 15px;
}

.h3_news{
  color:  #BE1E2D;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
}

.desc{
  color: #333333;
}

.date_news{
  color: #333333;
  font-size: 15px;
  padding-left: 5px;
}

.news_short_desc{
  /*height: 150px;*/
  min-height: 120px;
  max-height: 150;
}

.news_button{
  padding-left: 20px;
  padding-right: 20px;
}

h1{
  margin: 20px 0px 0px 0px;
}

.news_articles{
  display: inline-flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.article{
  margin: 20px;
  width: 100%;
}

.news_article_image{
  text-align: center;
  width: 100%;
  padding-bottom: 20px;
}

.text_article{
  text-align: left;
}

img{
  max-width:100%;
}

.news_article_text{
  padding: 0px 10px 30px 10px;
  width: 100%;
}

.h1_article{
  color:  #BE1E2D;
  font-weight: bold;
  margin-top: 0px;
  text-align: center;
}

.flex_sponsors{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 20px;
}

h1{
  font-size: 30px;
}

.CASUAL{
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgb(118, 214, 161);
  padding: 10px;
  color: #333333;
  font-size: 15px;
}

.CHAMPIONSHIP{
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: #eea629;
  padding: 10px;
  color: #333333;
  font-size: 15px;
}

.SPECIAL{
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: #7078eb;
  padding: 10px;
  color: white;
  font-size: 15px;
}

.CASUAL_event_page{
  background-color: rgb(118, 214, 161);
  width: 150px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #333333;
  font-size: 15px;
}

.CHAMPIONSHIP_event_page{
  background-color: #eea629;
  width: 150px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #333333;
  font-size: 15px;
}

.SPECIAL_event_page{
  background-color: #7078eb;
  width: 150px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: white;
  font-size: 15px;
}

@media (max-width: 500px){

  .share_h4{
    display: none;
  }

  .CASUAL_event_page{
    background-color: rgb(118, 214, 161);
    width: 100px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #333333;
    font-size: 10px;
  }

  .CHAMPIONSHIP_event_page{
    background-color: #eea629;
    width: 100px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #333333;
    font-size: 10px;
  }

  .SPECIAL_event_page{
    background-color: #7078eb;
    width: 100px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: white;
    font-size: 10px;
  }
}

.event_det_1{
  text-align: left;
}

.event_det_1_event_modal{
  text-align: center;
}

.top_image{
  position: relative;
  text-align: center;
}

.middle_details{
  border-bottom: solid 1px grey;
  text-align: left;
  padding: 10px 15px 20px 10px;
  position: relative;
  max-width: 380px;
}

.middle_details_event{
  text-align: center;
  padding: 10px 15px 20px 10px;
  position: relative;
  max-width: 100%;
}

.middle_details_news{
  text-align: left;
  padding: 10px 15px 20px 10px;
  position: relative;
  max-width: 380px;
  height: 170px;
}

.news_desc{
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  height: 60px;
}

.date_news{
  color: #cccccc;
}

.news_wrapper{
  width: 100%;
  max-width: 900px;
}

.read_more{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
}

.event_name{
  color: #BE1E2D;
  font-size: 25px;
  margin-bottom: 20px;
}

.more_info{
  background-color: #333333;
  color: grey;
  border: 2px solid grey;
  cursor:pointer;
  padding: 7px 15px 7px 15px;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.read_more_button{
  background-color: #333333;
  color: grey;
  border: 2px solid grey;
  cursor:pointer;
  padding: 7px 15px 7px 15px;
}

.bottom_details{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: stretch;
  padding: 20px;
}

.event_sub{
  padding-bottom: 10px;
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
}

.event_sub_event_modal{
  padding-bottom: 10px;
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
  color: #333333;
}

.share_h4{
  padding-bottom: 10px;
  font-size: 17px;
}

.slots_rem{
  font-size: 37px;
  color: #BE1E2D;
}

.event_date{
  padding-top: 10px;
  font-size: 20px;
}

.event_time{
  padding-top: 5px;
  font-size: 20px;
}

.ev_page_date_time{
  color: #333333;
}

.event_det_1_event_page{
  text-align: left;
}

.h1_home{
  color: white;
  margin: 15px 15px 10px;
  border-bottom: solid 1px #666;
  padding-bottom: 10px;
  padding-left: 20px;
  text-align: left;
}

.card_wrapper{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 50px;
}

.card{
  background-color: white;
  width: 80%;
  border: 5px solid #cccccc;
  box-shadow: 20px 20px 100px 20px rgba(0, 0, 0, .7);
}

.button_div{
  margin: 20px;
}

.contact_info{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  padding-right: 5px;
}

.contact_div_info{
  padding-top: 40px;
  height: 100%;
  width: 200px;
}

.contact_info_header{
  color: grey;
  font-size: 15px;
}

@media (max-width : 1295px) {
  .contact_info{
    flex-direction: row;
    justify-content: center;
  }
}

@media (max-width : 850px) {
  .contact_info{
    flex-direction: column;
    justify-content: center;
  }
}

.contact-wrapper{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.form{
  max-width: 800px;
  width: 90%;
}

p{
  margin: 20px;
  text-align: left;
}

.fas, .far, .fa-map-marker{
  color: grey;
}

.back{
  position: -webkit-sticky; /* Safari */
  top: 0;
  text-align: right;
  position: sticky;
  z-index: 100;
  height: 0px;
}

.fa-times-circle{
  color: black;
  margin-right: 10px;
  margin-top: 10px;
}

.fa-times-circle:hover{
  color: #BE1E2D;
}

.html_description{
  text-align: left;
  margin: 20px;
}

.flex_event_det{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-start;
  padding-left: 10px;
  padding-right: 10px;
}

.flex_details_event_sub{
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
}

.form_profile{
  max-width: 800px;
  width: 100%;
}

.form_wrapper_flex{
  display: flex;
  justify-content: center;
}

@media (max-width : 800px) {
  .minimise_sub{
    margin-top: 40px;
  }
}

.fa-user-circle{
  transition: 0.5s;
  color: white;
}

.fa-user-circle:hover{
  color: #BE1E2D;
}

.button_log_out {
  background-color: #BE1E2D;
  border: none;
  padding: 16px 32px;
  opacity: 0.6;
  transition: 0.3s;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  border-radius: 40px;
}

.button_log_out:hover {
  opacity: 1;
}

.fa-sign-out-alt{
  color: white;
}

.flex_news_article_social{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
}

.h1_article_div{
  margin-bottom: 20px;
}

.flex_event_article_social{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
}

.fa-clock{
  color: #cccccc;
}

.captcha_large{
  display: flex;
  justify-content: center;
}

@media (max-width: 450px)
{
  .captcha_large{
    display: flex;
    justify-content: center;
    transform:scale(0.7) !important;
    -webkit-transform:scale(0.7) !important;
  }
}

/*.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 700ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Body--open {
  overflow: hidden;
}*/
